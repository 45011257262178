

































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {RawLocation} from 'vue-router';

@Component
export default class UTab extends Vue {
  @Prop({required: true})
  public route!: RawLocation;

  @Prop({type: [String, Number]})
  public badge!: string | number;
}
